import { SupportedChainId } from '../connectors/index'

type AddressMap = { [chainId: number]: string }

export const RENART_ADDRESS: AddressMap = {
    [SupportedChainId.RINKEBY]: '0x8ab314eB7fb9cc8CBe050408A8FA93Be88C0E163',
    [SupportedChainId.HARDHAT]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    [SupportedChainId.MAINNET]: '0x970aAd14d99Ab8ff4DC699458a2183F44c1A6507',


}
