import styled from "styled-components"

const Title = styled.h1`
    color: #fff;
    font-size: 64px;
    line-height: 76px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 3.2px;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 48px;
        line-height: 58px;
    }
`

const HeroTitle = () => {
    return <Title data-aos="custom-fade-up" data-aos-delay="1000">The Renaissance Club</Title>
}

export default HeroTitle