import styled from 'styled-components'
import NftCard, { Props as CardProps } from './NftCard'

export interface Props {
    cardList: CardProps[]
}

const CardWrap = styled.div`
    width: 100%;
    max-width: 945px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    gap: 60px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const NftCardList = (props: Props) => {
    return (
        <CardWrap>
            {props.cardList.map((card, index) =>
                <NftCard {...card} key={index} onClick={card.onClick} />
            )}
        </CardWrap>
    )
}

export default NftCardList