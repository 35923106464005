import styled from 'styled-components';
import '../style/custom-border.css';

export interface Props {
  title: string,
  img: string,
  footer: string
  price: string
  priceColor?: string
  imgWidth?: string
  borderClass?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  aosDelay?: number
  aosName?: string
}

const Card = styled.div`
    width: 100%;
    @media (max-width: 576px) {
        max-width: 100%;
        margin: 0 auto;
        & + & {
            margin-top: 40px;
        }
    }
`

const NftCard = (props: Props) => {
  return (
    <Card
      className={props.borderClass || 'normal-border'}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-aos={props.aosName}
      data-aos-delay={props.aosDelay}
    >
      <div className='normal-border__wrap'>
        <div style={{ textAlign: 'center', fontSize: '24px', color: '#fff', lineHeight: 1.5, letterSpacing: '1.2px' }}>
          {props.title}
        </div>
        <div style={{ marginTop: '16px' }}>
          <img src={props.img} alt="card" style={{ width: '100%' }} />
        </div>
        <div style={{ marginTop: '16px', textAlign: 'center', color: '#fff' }}>
          <div style={{ color: 'rgba(178, 178, 178)', fontSize: '14px', lineHeight: 2, letterSpacing: '0.7px' }}>
            {props.footer}
          </div>
          <div style={{ marginTop: '4px', fontSize: '18px', color: props.priceColor || '#fff', lineHeight: 2, letterSpacing: '0.9px' }}>
            {props.price}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default NftCard