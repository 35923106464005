import { Modal } from 'antd';
import "antd/dist/antd.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AppFooter from './components/AppFooter';
import AppHeader from './components/AppHeader';
import HeroSubtitle from './components/HeroSubtitle';
import HeroTitle from './components/HeroTitle';
import InfoSection from './components/InfoSection';
import LoadingMaskV2 from './components/LoadingMaskV2';
import NftCard from './components/NftCard';
import NftCardList, { Props as NftCardListProps } from './components/NftCardList';
import { openNotificationWithIcon } from './helpers/notification';
import { useRenARTContract } from './hooks/useContract';
import { useActiveWeb3React } from './hooks/web3';
import bgLight from './images/bg-light-4.svg';
import wrapBg from './images/bg-pattern.png';
import cryptex from './images/cryptex.gif';
import "./style/fade-up.css";
import "./style/mint-modal.css";
import "./style/scroll-bar.css";
import VOUCHER_MAP_HARDHAT from './whitelist/whitelist_hardhat.json';
import VOUCHER_MAP_MAINNET from './whitelist/whitelist_mainnet.json';
import VOUCHER_MAP_RINKEBY from './whitelist/whitelist_rinkeby.json';


const MintButton = styled.button`
  display: inline-block;
  background-color: rgba(168, 139, 65);
  border: none;
  padding: 12px 48px;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  border-radius: 32px;
  margin-top: 40px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    cursor: pointer;
    background-color: rgba(233, 186, 85);
  }
`

const MintInputWrapper = styled.div`
    display: flex;
    padding: 16px 20px;
    max-width: 321px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 24px;
    letter-spacing: 1.2px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 24px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 24px 0;
    }
`

const AddButton = styled.div`
  background-color: #F2C055;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #000000;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    background-color: #E9C77E;
    cursor: pointer;
  }
  &:active {
    background-color: #CEA140;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    width: 10px;
    height: 1px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    width: 10px;
    height: 1px;
    background-color: #000;
    transform: translate(-50%, -50%);
  }
`

const MinusButton = styled.div`
  background-color: #262626;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    background-color: #4A4A4A;
    cursor: pointer;
  }
  &:active {
    background-color: #262626;
  }
  &::before {
    content: '';
    color: #fff;
    width: 10px;
    height: 1px;
    background-color: #fff;
  }
`

const Main = styled.main`
  padding-top: 120px;
`

const Section = styled.section`
  padding: 80px 12px 0;
  position: relative;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  @media (max-width: 768px) {
    padding: 60px 12px 0;
  }
`

const AppWrap = styled.div`
  background-image: url(${bgLight}), url(${wrapBg});
  background-repeat: no-repeat, repeat;
  background-size: auto, 100%;
`

const ModalText = styled.div`
  color: #fff;
  font-size: 36px;
  letter-spacing: 1.8px;
  line-height: 1.5;
  text-align: center;
`

const ModalMintWrap = styled.div`
  display: flex;
  max-width: 920px;
  gap: 48px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

// const MintAvaliable = styled.div`
//   font-size: 14px;
//   color: rgba(178, 178, 178);
//   letter-spacing: 0.7px;
//   line-height: 2;
//   margin-top: 16px;
// `

const MintContentWrap = styled.div`
  flex: 0 0 436px;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`

type StageInfo = {
  publicStage: boolean;
  stageId: number;
  maxSupply: number;
  startTime: number;
  endTime: number;
  mintPrice: BigNumber;
}
type Voucher = {
  voucher: {
    redeemer: string;
    stageId: number;
    amount: number;
    nonce: number;
    price: string;
  };
  signature: string;
}


export enum SupportedChainId {
  MAINNET = 1,
  RINKEBY = 4,
  HARDHAT = 1337,
}

const voucherMapHardhat = new Map(Object.entries(VOUCHER_MAP_HARDHAT));
const voucherMapRinkeby = new Map(Object.entries(VOUCHER_MAP_RINKEBY));
const voucherMapMainnet = new Map(Object.entries(VOUCHER_MAP_MAINNET));

const voucherMap: { [key in SupportedChainId]: Map<string, Voucher> } = {
  [SupportedChainId.MAINNET]: voucherMapMainnet,
  [SupportedChainId.RINKEBY]: voucherMapRinkeby,
  [SupportedChainId.HARDHAT]: voucherMapHardhat,
}

enum CardIndex {
  OG = 0,
  WHITELIST = 1,
  PUBLIC = 2,
}

function App() {
  const { account, chainId } = useActiveWeb3React();
  const [isModalVisible, setModalVisible] = useState(false)
  const [mintAmount, setMintAmount] = useState('1');
  const [activeCardIndex, setActiveCardIndex] = useState<null | CardIndex>(null);
  const [stageInfo, setStageInfo] = useState<StageInfo>()
  const renArtContract = useRenARTContract();
  const [mintButtonDisabled, setMintButtonDisabled] = useState(false);
  // eslint-disable-next-line 
  const [avaibleMintAmount, setAvaibleMintAmount] = useState(0);

  useEffect(() => {
    if (!chainId || !account) return;

    if (chainId !== 1 && chainId !== 1337 && chainId !== 4) {
      openNotificationWithIcon('info', 'Please use Ethereum mainnet', 'Switch your network to Ethereum mainnet')
      return;
    }
    const getStageInfto = async () => {
      const _stageInfo = await renArtContract?.stageInfo()
      setStageInfo(_stageInfo)
      const whitelist = voucherMap[chainId].get(account)
      if (whitelist) {
        console.log('in whitelist')

        const info = (await renArtContract?.whitelistInfo(account))
        if (info?.nonce && info?.nonce >= whitelist.voucher.stageId) {
          setAvaibleMintAmount(info['remain'].toNumber())
        }
        else {
          setAvaibleMintAmount(whitelist.voucher.amount)
        }
      }
    }
    getStageInfto()
  }, [chainId, renArtContract, account])

  useEffect(() => {
    // scroll to top on refresh
    window.history.scrollRestoration = 'manual'

    // freeze scroll until loading is complete
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    const timer = setTimeout(() => {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'auto';
    }, 1760)
    // add 10 ms for buffer

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 300,
      offset: 80,
      once: true,
      easing: 'ease-in'
    });
  }, []);

  const handleMint = async () => {

    if (!account || !chainId) {
      openNotificationWithIcon('info', 'Please connect to your wallet', '')
      return
    }
    if (chainId !== 1 && chainId !== 1337 && chainId !== 4) {
      openNotificationWithIcon('info', 'Please use Ethereum mainnet', 'Switch your network to Ethereum mainnet')
      return;
    }

    if (!mintAmount || !parseInt(mintAmount)) {
      openNotificationWithIcon('info', 'Please enter your mint amount', '')
      return

    }
    if (parseInt(mintAmount) <= 0) {
      openNotificationWithIcon('info', 'Please enter valid mint amount', '')
      return
    }


    // const data = voucherMap[chainId].get(account)
    // const voucher = data?.voucher

    // if (!voucher) {
    //   openNotificationWithIcon('info', 'Not in whitelist', '')
    //   return
    // }
    if (stageInfo?.startTime && stageInfo?.endTime) {

      if (Date.now() < stageInfo?.startTime * 1000) {
        openNotificationWithIcon('info', "Sale has'nt started yet", '')
        return

      }
      if (Date.now() > stageInfo?.endTime * 1000) {

        openNotificationWithIcon('info', "Sale has already ended", '')
        return
      }

    }
    // if (!avaibleMintAmount) {
    //   openNotificationWithIcon('info', 'No remaining mint amount', '')
    //   return
    // }
    // const signature = data?.signature
    try {


      setMintButtonDisabled(true)
      // const tx = await renArtContract?.whitelistMint(voucher, signature, mintAmount, {
      //   value: BigNumber.from(voucher.price).mul(mintAmount)
      // })
      const tx = await renArtContract?.publicMint(mintAmount, {
        value: BigNumber.from(stageInfo?.mintPrice).mul(mintAmount)
      })
      const receipt = await tx?.wait()
      if (receipt?.status) {
        openNotificationWithIcon('success', 'Mint success!', "Welcome to the Renaissance Club!")
        setMintAmount('1')
        setModalVisible(false)
        const info = (await renArtContract?.whitelistInfo(account))
        if (info?.nonce) {
          setAvaibleMintAmount(info['remain'].toNumber())
        }
      }
    } catch (err) {
      setMintButtonDisabled(false)
      // @ts-ignore:next-line
      if (err.code === "INSUFFICIENT_FUNDS") {
        openNotificationWithIcon('error', 'INSUFFICIENT FUND', "You might not have enough fund to perform this opertaion.")
        return
      }
      // @ts-ignore:next-line
      if (err.code === 4001) {
        openNotificationWithIcon('error', 'User denied transaction signature', "")
        return
      }
      // @ts-ignore:next-line
      // openNotificationWithIcon('error', 'Error while sending transaction', "You might use up all your mintable quota!" + err.message)
      openNotificationWithIcon('error', 'Error while sending transaction', err.message)

    }
    setMintButtonDisabled(false)
  }

  const cardList: NftCardListProps['cardList'] = [
    {
      title: 'OG Whitelist',
      img: cryptex, footer: 'Mint Price',
      price: 'Sold out',
      onClick: () => {
        setActiveCardIndex(CardIndex.OG)
        setModalVisible(true)
      },
      aosDelay: 1500,
      aosName: 'custom-fade-up',
    },
    {
      title: 'Whitelist',
      img: cryptex, footer: 'Mint Price',
      price: 'Sold out',
      onClick: () => {
        setActiveCardIndex(CardIndex.WHITELIST)
        setModalVisible(true)
      },
      aosDelay: 1600,
      aosName: 'custom-fade-up',
    },
    {
      title: 'Public Sale',
      img: cryptex, footer: 'Mint Price',
      price: '0.35 ETH',
      onClick: () => {
        setActiveCardIndex(CardIndex.PUBLIC)
        setModalVisible(true)
      },
      aosDelay: 1700,
      aosName: 'custom-fade-up',
    },
  ]


  const handleModalClose = () => {
    setModalVisible(false)
    setActiveCardIndex(null)
  }

  const handleClickMinus = () => {
    if (Number(mintAmount) <= 1) return
    setMintAmount((Number(mintAmount) - 1).toString())
  }

  return (
    <AppWrap>
      <AppHeader />
      <Main>
        <Section>
          <HeroTitle />
          <HeroSubtitle />
          <NftCardList cardList={cardList} />
          <InfoSection />
        </Section>
      </Main>
      <AppFooter />
      <Modal
        visible={isModalVisible}
        footer={null}
        wrapClassName='mint-modal'
        centered={true}
        onCancel={handleModalClose}
        closeIcon={
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
              <line x1="13.9897" y1="24.9602" x2="25.3034" y2="13.6465" stroke="white"></line>
              <line x1="14.6968" y1="13.9896" x2="26.0105" y2="25.3033" stroke="white"></line>
            </g>
          </svg>
        }
        transitionName="ant-slide-down"
      >
        {
          activeCardIndex === 0 && <ModalText>Expired</ModalText>
        }
        {
          activeCardIndex === 1 && <ModalText>Expired</ModalText>
        }
        {
          activeCardIndex === 2 &&
          <ModalMintWrap>
            <MintContentWrap>
              <div style={{ maxWidth: '275px', margin: '0 auto' }}>
                <NftCard title={cardList[activeCardIndex].title} img={cardList[activeCardIndex].img} footer={cardList[activeCardIndex].footer} price={cardList[activeCardIndex].price} />
              </div>
            </MintContentWrap>
            <MintContentWrap style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <ModalText>Mint Amount</ModalText>
              <MintInputWrapper>
                <MinusButton onClick={handleClickMinus} />
                <span>{mintAmount}</span>
                <AddButton onClick={Number(mintAmount) < 5 ? () => { setMintAmount((Number(mintAmount) + 1).toString()) } : () => { }} />
              </MintInputWrapper>
              <MintButton onClick={handleMint} disabled={mintButtonDisabled}>
                Mint Now
              </MintButton>
              {/* <MintAvaliable>Available Mont : {avaibleMintAmount}</MintAvaliable> */}
            </MintContentWrap>
          </ModalMintWrap>
        }
      </Modal>
      <LoadingMaskV2 />
    </AppWrap>
  );
}

export default App;
