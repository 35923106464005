import styled from 'styled-components';
import AppLogo from '../images/logo-renart.svg';
import Account from './Account'

const Header = styled.header` 
  padding: 0 40px;
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  @media (max-width: 768px) {
      padding: 20px 12px;
      flex-wrap: wrap;
      height: auto;
  }
`

const Brand = styled.a`
  flex-shrink: 0;
  width: 160px;
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  @media (max-width: 768px) { 
    width: 100px;
  }
`

const AppHeader = () => {
  return (
    <Header>
      <Brand href="/" >
        <img src={AppLogo} alt="logo" />
      </Brand>
      <Account />
    </Header>
  )
}

export default AppHeader