import { useState, useEffect } from 'react';
import loadingBg from '../images/bg-loading-v2.jpg';
import styled, { keyframes } from 'styled-components';

export const slideUp = keyframes`
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(0, -80px);
    opacity: 0;
  }
`;

const fade = keyframes`
  0% {
    visibility: inherit;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

const Container = styled.div`
  background-color: #000;
  background-image: url(${loadingBg});
  background-size: cover;
  padding-left: 12px;
  padding-right: 12px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 200;
  animation-name: ${fade};
  animation-delay: 0.75s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  color: #fff;
  text-align: center;
`

const Title = styled.div`
  margin-top: 192px;
  font-size: 64px;
  letter-spacing: 3.2px;
  line-height: 96px;
  animation-name: ${slideUp};
  animation-duration: 0.45s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  @media (max-width: 768px) { 
    margin-top: 144px;
    letter-spacing: 1.5px;
    font-size: 30px;
    line-height: 45px;
  }
`
const Desc = styled.p`
  font-size: 18px;
  letter-spacing: 0.9px;
  margin: 0;
  margin-top: 16px;
  line-height: 27px;
  animation-name: ${slideUp};
  animation-delay: 0.15s;
  animation-duration: 0.45s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  @media (max-width: 768px) { 
    margin-top: 16px;
  }
`
const Count = styled.p`
  margin-top: 256px;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.8px;
  animation-name: ${slideUp};
  animation-delay: 0.3s;
  animation-duration: 0.45s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  @media (max-width: 768px) { 
    margin-top: 160px;
  }
`

const LoadingMaskV2 = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(100);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <Inner>
        <Title>
          The Renaissance Club
        </Title>
        <Desc>
          RenArt is an NFT project composed of classic paintings of Renaissance masters 600 years ago.
        </Desc>
        <Count>{count}%</Count>
      </Inner>
    </Container>
  )
}

export default LoadingMaskV2;