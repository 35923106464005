import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { injected } from '../connectors';
import { getEllipsisTxt } from '../helpers/formatters';
import { useActiveWeb3React } from '../hooks/web3';
import MetaMaskIcon from '../images/metamask.png';
import '../style/custom-modal.css';
import { isMobile } from '../utils/userAgent';

declare let window: any;

interface Props {

}
const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    height: "42px",
    fontWeight: "500",
    fontSize: "14px",
    padding: "6px 32px",
    color: '#fff'
  },
  iconWrapper: {
    alignItems: "center",
    justifyContent: "center",
  }
}
const Box = styled('div')`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    background-color: rgba(255, 255, 255,0.05);
    font-weight: 400;
    border: solid 1px rgba(255, 255, 255,0.2);
    :hover {
        cursor: pointer;
    }
`

const ButtonContainer = styled.div`
  & .ant-btn-primary {
    background-color: transparent;
    border: solid 1px rgba(168, 139, 65, 0.7) !important;
    transition: 0.3s;
    border-radius: 32px;
    &:hover {
        background-color: rgba(168, 139, 65);
    }  
    @media (max-width: 768px) {
        margin-left: auto;
        margin-right: 12px;
    }
  }
`

const Title = styled.div`
  color: #fff;
  font-size: 36px;
  letter-spacing: 1.8px;
  line-height: 1.5;
  margin-bottom: 24px;
  text-align: center;
`

const Text = styled.div`
  color: #fff;
  line-height: 2;
  letter-spacing: 0.8px;
  font-size: 16px;
`

const Account = (props: Props) => {
  const { activate, account } = useActiveWeb3React()
  const [isModalOpen, setModalOpen] = useState(false);
  const isTablet = useMediaQuery({
    query: '(min-width: 992px)'
  })
  if (account) {
    return (
      <div>
        <ButtonContainer>
          <Button type='primary' style={{ ...styles.button, border: (isTablet ? "none" : "") }}>
            <span style={{ lineHeight: '28px', letterSpacing: '0.8px', fontSize: '16px' }}>
              {getEllipsisTxt(account)}
            </span>
          </Button>
        </ButtonContainer>
      </div>
    )
  }
  return (
    <div>
      <ButtonContainer>
        <Button type='primary' style={{ ...styles.button, border: (isTablet ? "none" : "") }} onClick={() => { setModalOpen(true) }}>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="4.5" x2="10" y2="4.5" stroke="white"></line>
            <line x1="5.5" y1="2.18557e-08" x2="5.5" y2="10" stroke="white"></line>
          </svg>
          <span style={{ marginLeft: '6px', lineHeight: '28px', letterSpacing: '0.8px', fontSize: '16px' }}>Connect Wallet</span>
        </Button>
      </ButtonContainer>
      <Modal
        visible={isModalOpen}
        footer={null}
        wrapClassName="custom-modal"
        transitionName="ant-move-down"
        maskStyle={{ background: 'rgba(0,0,0,0.7)' }}
        centered
        closeIcon={<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.7">
            <line x1="13.9897" y1="24.9602" x2="25.3034" y2="13.6465" stroke="white"></line>
            <line x1="14.6968" y1="13.9896" x2="26.0105" y2="25.3033" stroke="white"></line>
          </g>
        </svg>}
        onCancel={() => { setModalOpen(false) }}
      >
        <Title>
          Connect Wallet
        </Title>
        {
          window.ethereum ?
            <Box onClick={() => {
              activate(injected, undefined, true)
                .catch(err => { console.log(err) })
                .finally(() => { setModalOpen(false) })
            }}>
              <Text>Metamask</Text>
              <div style={styles.iconWrapper}>
                <img src={MetaMaskIcon} alt="Icon" height="24px" width="24px" />
              </div>
            </Box>
            :
            (isMobile ?
              <a href="https://metamask.app.link/dapp/mintpage.renart.io/" target="_blank" rel="noreferrer">
                <Box>
                  <Text>Install or Open Metamask</Text>
                  <div style={styles.iconWrapper}>
                    <img src={MetaMaskIcon} alt="Icon" height="24px" width="24px" />
                  </div>
                </Box>
              </a> :
              <a href="https://metamask.io" target="_blank" rel="noreferrer">
                <Box>
                  <Text>Install Metamask</Text>
                  <div style={styles.iconWrapper}>
                    <img src={MetaMaskIcon} alt="Icon" height="24px" width="24px" />
                  </div>
                </Box>
              </a>
            )
        }
      </Modal>
    </div >
  )
}

export default Account
