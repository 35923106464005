import styled from "styled-components"

const SubTitle = styled.p`
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.8px;
    margin: 16px 0 0;
`

const HeroSubtitle = () => {
    return <SubTitle data-aos="custom-fade-up" data-aos-delay="1200" > RenArt is an NFT project composed of classic paintings of Renaissance masters 600 years ago. </SubTitle>
}

export default HeroSubtitle