
import infoBg from '../images/bg-collab-details.jpg'
import '../style/info-section.css'

const InfoSection = () => {
    return (
        <div data-aos="custom-fade-up" data-aos-delay="50" data-aos-offset="250" className='info-section-wrap' style={{ backgroundImage: `url(${infoBg})` }}>
            <div className='info-section' >
                <div className='info-section__head'>
                    <h2 className='info-section__head-title'>Collab Details</h2>
                    <div className='info-section__head-contents'>
                        <div className='info-section__head-subtitle'>Total Price</div>
                        <div className='info-section__head-content'>ETH + Gas Fee</div>
                    </div>
                </div>
                <div className='info-section__body'>
                    <div className='info-section__body-wrap'>
                        <h3 className='info-section__body-title'>Public Mint Date</h3>
                        <div className='info-section__body-time'>
                            <div className='time-item'>
                                <div className='time-item__head'>Friday,</div>
                                <div className='time-item__body'>07/01/22</div>
                                <div className='time-item__footer'>9pm UTC+8</div>
                            </div>
                            <div>
                                <svg className='divider-lg' width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.765625 4.5952H34.1814C34.1814 4.5952 32.002 6.17946 31.0392 6.87968C31.0917 6.95846 31.1442 7.04599 31.1967 7.12476C34.2252 6.12694 37.2012 5.12036 40.2296 4.12254C40.2121 4.00876 40.2472 3.89497 40.2296 3.78994C37.2362 2.80962 34.2515 1.84681 31.2668 0.875244C31.2317 0.980278 31.1967 1.07656 31.1617 1.18159C32.037 1.82055 34.1814 3.54191 34.1814 3.54191H0.765625V4.5952Z" fill="#A7976D"></path>
                                </svg>
                                <svg className='divider-sm' width="7" height="40" viewBox="0 0 7 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.53005 0.265381L2.53005 33.6812C2.53005 33.6812 0.945792 31.5017 0.245566 30.5389C0.16679 30.5915 0.0792619 30.644 0.00048655 30.6965C0.998309 33.725 2.00488 36.7009 3.00271 39.7294C3.11649 39.7119 3.23028 39.7469 3.33531 39.7294C4.31563 36.7359 5.27844 33.7512 6.25 30.7665C6.14497 30.7315 6.04869 30.6965 5.94366 30.6615C5.3047 31.5368 3.58334 33.6812 3.58334 33.6812L3.58333 0.265381L2.53005 0.265381Z" fill="#A7976D"></path>
                                </svg>
                            </div>
                            <div className='time-item'>
                                <div className='time-item__head'>Wednesday,</div>
                                <div className='time-item__body'>12/31/25</div>
                                <div className='time-item__footer'>9pm UTC+8</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='info-section__footer'>
                    <div className='info-section__footer-wrap'>

                        <h3 className='info-section__footer-title'>Whitelist Mint Date</h3>
                        <div className='info-section__footer-content'>END</div>
                    </div>
                </div>
            </div>
            <div className='overlay' />
        </div>
    )
}

export default InfoSection
